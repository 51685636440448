import { SocialLink } from '@/types/common';

export const socialLinks: SocialLink[] = [
  {
    name: 'facebook',
    href: 'https://www.facebook.com/cleanmymac',
    isShareable: true,
  },
  {
    name: 'instagram',
    href: 'https://www.instagram.com/cleanmymac',
    isShareable: false,
  },
  {
    name: 'twitter',
    href: 'https://twitter.com/cleanmymac',
    isShareable: true,
  },
  {
    name: 'youtube',
    href: 'https://www.youtube.com/user/CleanMyMac',
    isShareable: false,
  },
  {
    name: 'linkedin',
    isShareable: true,
  },
];
