import clsx from 'clsx';
import { ReactElement } from 'react';

interface Props {
  handleClick: () => void;
  isMenuOpen: boolean;
  className?: string;
}

const MobileMenu = ({ className, handleClick, isMenuOpen }: Props): ReactElement => {
  const buttonClassName = clsx('flex flex-col justify-center items-center p-3', className);

  const baseSpanClass = 'bg-black block transition-all duration-300 ease-out h-0.5 w-4 rounded-sm';

  return (
    <button className={buttonClassName} type="button" onClick={handleClick} data-qa="toggle-menu-header-desktop">
      <span className={clsx(baseSpanClass, isMenuOpen ? 'translate-y-1 rotate-45' : '-translate-y-0.5')}></span>
      <span className={clsx(baseSpanClass, 'my-0.5', isMenuOpen ? 'opacity-0' : 'opacity-100')}></span>
      <span className={clsx(baseSpanClass, isMenuOpen ? '-translate-y-1 -rotate-45' : 'translate-y-0.5')}></span>
    </button>
  );
};

export { MobileMenu };
