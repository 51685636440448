import Image, { ImageLoaderProps, ImageProps } from 'next/image';

import { IS_PRODUCTION } from '@/config/global';

function normalizeSrc(src: string) {
  return src.startsWith('/') ? src : '/' + src;
}

function customLoader(props: ImageLoaderProps) {
  if (!IS_PRODUCTION) {
    return normalizeSrc(props.src);
  }

  return optimisedLoader(props);
}

function optimisedLoader({ src, width, quality }: ImageLoaderProps) {
  const params = ['format=auto', `width=${width}`];

  if (quality) {
    params.push(`quality=${quality}`);
  }

  return '/cdn-cgi/image/' + params.join(',') + normalizeSrc(src);
}

type OptimizedImageProps = Omit<ImageProps, 'alt'> & { alt?: string };

export default function OptimizedImage(props: OptimizedImageProps): JSX.Element {
  const { alt = '', ...otherProps } = props;

  return <Image loader={customLoader} alt={alt} {...otherProps} />;
}
