/* @Config */
import { ORIGIN } from '@/config/global';

/* @Hooks */
import { useRouter } from 'next/router';

function useCanonical(): string {
  const { asPath } = useRouter();
  const { origin, pathname } = new URL(asPath, ORIGIN);

  return `${origin}${pathname}`;
}

export { useCanonical };
