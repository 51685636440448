const analyticalData = {
  eventCategory: 'CleanMyMac 5',
  eventAction: {
    onBuyNowClick: 'Click Buy Now',
    onDownloadClick: 'Click Download',
    onDownloadShow: 'Show Download',
    onFormSubscribe: {
      mainPage: 'On-Page Subscribe Form',
    },
    whatAbout: 'What About Block',
  },
  eventLabel: {
    header: 'Header',
    topper: 'Topper',
    floatingBar: 'Floating Bar',
    middleFirst: 'Middle First',
    middleSecond: 'Middle Second',
    blog: {
      bottomBanner: 'Blog (Bottom Banner)',
    },
    closer: 'Closer',
    form: {
      impression: 'Impression',
      success: 'Email Opt-In',
    },
    input: {
      empty: 'Subscription Error - Empty',
      invalid: 'Subscription Error - Invalid',
    },
    faq: {
      1: 'Is this paid software?',
      2: 'Is CleanMyMac safe to use?',
      3: 'How does this work?',
    },
  },
};

export { analyticalData };
