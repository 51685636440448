import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useIntl } from 'react-intl';
import { useAnalytics } from '@/hooks/use-analytics';
import { analyticalData } from '@/config/analytics';
import { links } from '@/config/links';

import ActiveLink from '@/components/active-link';
import OptimizedImage from '@/components/optimized-image';
import TopBanner from '@/components/top-banner';

function Header(): JSX.Element {
  const { formatMessage } = useIntl();
  const [isMobileMenuHidden, setIsMobileMenuHidden] = useState(true);
  const headerRef = useRef<HTMLHeadElement>(null);
  const { sendEvent } = useAnalytics();

  useEffect(() => {
    const handleHeaderScroll = () => {
      if (window.scrollY > 0 && headerRef.current) {
        headerRef.current?.classList.add('shadow-md');
      } else {
        headerRef.current?.classList.remove('shadow-md');
      }
    };

    window.addEventListener('scroll', handleHeaderScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleHeaderScroll);
  }, []);

  const openMobileMenu = () => {
    setIsMobileMenuHidden(false);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuHidden(true);
  };

  const handleLinkClick = () => closeMobileMenu();

  const handleBuyNowClick = () => {
    sendEvent({
      eventCategory: analyticalData.eventCategory,
      eventAction: analyticalData.eventAction.onBuyNowClick,
      eventLabel: analyticalData.eventLabel.header,
    });
  };

  const handleDownloadClick = () => {
    sendEvent({
      eventCategory: analyticalData.eventCategory,
      eventAction: analyticalData.eventAction.onDownloadClick,
      eventLabel: analyticalData.eventLabel.header,
    });
  };

  return (
    <>
      <header className="sticky left-0 top-0 z-40 mb-8 w-full bg-white md:mb-10" ref={headerRef}>
        <TopBanner />
        <div className="mx-auto flex max-w-screen-lg items-center justify-between px-4 py-4 lg:py-0">
          <Link href={links.internal.main} className="flex items-center space-x-2" data-qa="logo-header-desktop">
            <OptimizedImage src="/images/cmm_logo.png" alt="CleanMyMac logo" width="36" height="36" />
            <span className="font-semibold">CleanMyMac</span>
          </Link>
          <nav className="hidden lg:block" aria-label="primary">
            <ul className="flex space-x-8">
              <li>
                <ActiveLink
                  href={links.internal.main}
                  classes={{
                    link: 'link',
                    active: {
                      link: 'link--current',
                    },
                  }}
                  qaAttribute="main-page-header-link-desktop"
                >
                  {formatMessage({ defaultMessage: 'main', description: 'header menu item' })}
                </ActiveLink>
              </li>
              <li>
                <ActiveLink
                  href={links.internal.blog}
                  classes={{
                    link: 'link',
                    active: {
                      link: 'link--current',
                    },
                  }}
                  qaAttribute="blog-page-header-link-desktop"
                >
                  {formatMessage({ defaultMessage: 'blog', description: 'header menu item' })}
                </ActiveLink>
              </li>
              <li>
                <ActiveLink
                  href={links.internal.support}
                  classes={{
                    link: 'link',
                    active: {
                      link: 'link--current',
                    },
                  }}
                  qaAttribute="support-page-header-link-desktop"
                >
                  {formatMessage({ defaultMessage: 'support', description: 'header menu item' })}
                </ActiveLink>
              </li>
            </ul>
          </nav>
          <nav className="hidden lg:block" aria-label="secondary">
            <ul className="flex space-x-4">
              <li>
                <a
                  className="btn btn-green h-10"
                  target="_blank"
                  href={links.cmmStore}
                  rel="noreferrer"
                  data-qa="buy-now-header"
                  onClick={handleBuyNowClick}
                >
                  {formatMessage({ defaultMessage: 'Buy now' })}
                </a>
              </li>
              <li>
                <a
                  href={links.cmmDownload}
                  className="btn btn-outline btn-outline-gray h-10"
                  target="_blank"
                  rel="noreferrer"
                  data-qa="free-download-header"
                  onClick={handleDownloadClick}
                >
                  {formatMessage({ defaultMessage: 'Free download' })}
                </a>
              </li>
            </ul>
          </nav>
          <button
            className="text-sm lg:hidden"
            type="button"
            onClick={openMobileMenu}
            data-qa="toggle-menu-heder-desktop"
          >
            {formatMessage({ defaultMessage: 'menu', description: 'menu toggle button' })}
          </button>
        </div>

        <div className="fixed left-0 top-0 z-50 h-full w-full bg-white px-4 py-4" hidden={isMobileMenuHidden}>
          <div className="flex h-full flex-col">
            <div className="mb-4 flex items-center justify-between">
              <Link
                href={links.internal.main}
                className="flex items-center space-x-2"
                onClick={handleLinkClick}
                data-qa="main-page-header-link-mobile"
              >
                <OptimizedImage src="/images/cmm5-simple.png" alt="CleanMyMac" width="32" height="25" />
                <span className="font-semibold">CleanMyMac</span>
              </Link>
              <button
                className="relative text-sm font-bold before:absolute before:-bottom-4 before:block before:h-[2px] before:w-full before:bg-macpaw-black"
                type="button"
                onClick={closeMobileMenu}
                data-qa="toggle-menu-heder-mobile"
              >
                {formatMessage({ defaultMessage: 'menu', description: 'menu toggle button' })}
              </button>
            </div>
            <nav className="flex flex-1 items-center">
              <ul className="space-y-5">
                <li>
                  <ActiveLink
                    onClick={handleLinkClick}
                    href={links.internal.main}
                    classes={{
                      link: 'link',
                      active: {
                        link: 'link--current',
                      },
                    }}
                    qaAttribute="main-page-header-link-mobile"
                  >
                    {formatMessage({ defaultMessage: 'main', description: 'header menu item' })}
                  </ActiveLink>
                </li>
                <li>
                  <ActiveLink
                    onClick={handleLinkClick}
                    href={links.internal.blog}
                    classes={{
                      link: 'link',
                      active: {
                        link: 'link--current',
                      },
                    }}
                    qaAttribute="blog-page-header-link-mobile"
                  >
                    {formatMessage({ defaultMessage: 'blog', description: 'header menu item' })}
                  </ActiveLink>
                </li>
                <li>
                  <ActiveLink
                    onClick={handleLinkClick}
                    href={links.internal.support}
                    classes={{
                      link: 'link',
                      active: {
                        link: 'link--current',
                      },
                    }}
                    qaAttribute="support-page-header-link-mobile"
                  >
                    {formatMessage({ defaultMessage: 'support', description: 'header menu item' })}
                  </ActiveLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}

export { Header };
