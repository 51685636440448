/* @Hooks */
import { useIntl } from 'react-intl';

import { SocialLink } from '@/types/common';

/* @Icons */
import Facebook from '@/icons/facebook.svg';
import Instagram from '@/icons/instagram.svg';
import Twitter from '@/icons/twitter.svg';
import Youtube from '@/icons/youtube.svg';

interface FooterProps {
  socialLinks: SocialLink[];
}

const socialIconByName: { [iconName: string]: JSX.Element } = {
  facebook: <Facebook />,
  instagram: <Instagram />,
  twitter: <Twitter />,
  youtube: <Youtube />,
};

function Footer(props: FooterProps): JSX.Element {
  const { socialLinks } = props;
  const { formatMessage } = useIntl();
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-white">
      <div className="mx-auto flex max-w-screen-lg flex-col-reverse items-center justify-center px-4 pb-4 pt-10 text-center md:flex-row md:items-start md:space-x-10 md:pb-12 md:pt-6 md:text-left">
        <p className="w-full text-left text-sm opacity-40 md:w-3/12 lg:w-4/12">
          {formatMessage({ defaultMessage: 'Copyright © {currentYear} MacPaw Way Ltd.' }, { currentYear })}
        </p>
        <nav className="mb-14 w-full text-left md:mb-0 md:w-6/12">
          <ul className="inline-block space-y-9 text-left md:columns-2 md:gap-10 md:space-y-2">
            <li>
              <a
                target="_blank"
                href="https://macpaw.com/ja/cookies"
                rel="noreferrer"
                className="border-b-2 border-transparent text-sm transition-colors hover:border-current"
                data-qa="cookies-footer"
              >
                {formatMessage({ defaultMessage: 'Cookie policy' })}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://macpaw.com/ja/policy"
                rel="noreferrer"
                className="border-b-2 border-transparent text-sm transition-colors hover:border-current"
                data-qa="policy-footer"
              >
                {formatMessage({ defaultMessage: 'Privacy policy' })}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://macpaw.com/ja/refund-policy"
                rel="noreferrer"
                className="border-b-2 border-transparent text-sm transition-colors hover:border-current"
                data-qa="refund-footer"
              >
                {formatMessage({ defaultMessage: 'Refund policy' })}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://macpaw.com/ja/eula"
                rel="noreferrer"
                className="border-b-2 border-transparent text-sm transition-colors hover:border-current"
                data-qa="eula-footer"
              >
                {formatMessage({ defaultMessage: 'License agreement' })}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://macpaw.com/ja/support/cleanmymac/knowledgebase/uninstall-cleanmymac-x"
                rel="noreferrer"
                className="border-b-2 border-transparent text-sm transition-colors hover:border-current"
                data-qa="uninstall-footer"
              >
                {formatMessage({ defaultMessage: 'Uninstall' })}
              </a>
            </li>
          </ul>
        </nav>
        <nav className="mb-12 w-full md:w-3/12 lg:w-2/12">
          <p className="mb-4 text-sm opacity-50 md:mb-3">{formatMessage({ defaultMessage: 'follow us' })}</p>
          <ul className="flex justify-center space-x-[10px] md:justify-start">
            {socialLinks.map(
              ({ name, href }) =>
                href && (
                  <li key={name}>
                    <a
                      href={href}
                      aria-label={name}
                      target="_blank"
                      rel="noreferrer"
                      className="flex h-11 w-11 items-center justify-center rounded-2.5 bg-black bg-opacity-5 transition-colors hover:bg-opacity-10 active:bg-opacity-20 md:h-8 md:w-8"
                      data-qa={`${name}-footer`}
                    >
                      {socialIconByName[name]}
                    </a>
                  </li>
                )
            )}
          </ul>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
