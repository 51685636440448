export const links = {
  cmmDownload: 'https://macpaw.com/ja/download/cleanmymac',
  cmmStore: 'https://macpaw.com/ja/store/cleanmymac',
  cmmProductPage: 'https://macpaw.com/cleanmymac',
  macpawStore: 'https://macpaw.com/ja/store',
  cmpAppStoreFloatingBanner: 'https://macpaw.onelink.me/Mw8m/x9ty1pah',
  cmpAppStoreBottomBanner: 'https://macpaw.onelink.me/Mw8m/6pu2quzg',
  cmpAppStorePrecontentBanner: 'https://macpaw.onelink.me/Mw8m/3ih8f4cn',
  cmpProductPage: 'https://macpaw.com/cleanmyphone',
  policy: {
    privacy: 'https://macpaw.com/ja/policy',
  },
  internal: {
    main: '/',
    blog: '/blog',
    support: '/support/contact',
  },
};
