import { ReactNode } from 'react';
import { useRouter } from 'next/router';

import { Header, HeaderNew } from '@/components/header';
import Footer from '@/components/footer';
import { socialLinks } from '@/config/social-links';

interface LayoutProps {
  children: ReactNode;
}

function Layout(props: LayoutProps): JSX.Element {
  const router = useRouter();
  // TODO: try to get this value from from server side
  const isHomePage = router.pathname === '/';

  if (isHomePage) {
    return (
      <div className="bg-[#FAFAFA]">
        <HeaderNew />
        <main className="flex flex-grow flex-col bg-[#FAFAFA] pt-8 font-fixel lg:pt-32">{props.children}</main>
        <Footer socialLinks={socialLinks} />
      </div>
    );
  }
  return (
    <>
      <Header />
      <main className="flex flex-grow flex-col">{props.children}</main>
      <Footer socialLinks={socialLinks} />
    </>
  );
}

export default Layout;
