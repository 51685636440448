import { ReactElement, ReactNode, MouseEventHandler } from 'react';
import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import clsx from 'clsx';

interface ActiveLinkClasses {
  root?: string;
  link?: string;
  active?: {
    root?: string;
    link?: string;
  };
}

interface ActiveLinkProps extends Omit<LinkProps, 'className'> {
  children: ReactNode;
  classes?: ActiveLinkClasses;
  onClick?: MouseEventHandler;
  qaAttribute?: string;
}

const ActiveLink = ({
  children,
  href,
  classes = {},
  onClick,
  qaAttribute,
  ...otherLinkProps
}: ActiveLinkProps): ReactElement => {
  const { pathname, asPath } = useRouter();

  const isActive = pathname === href || (href === '/blog' && asPath.startsWith('/blog'));

  const rootClassname = clsx(classes.root, isActive && classes.active?.root);
  const linkClassname = clsx(classes.link, isActive && classes.active?.link);

  return (
    <div className={rootClassname}>
      <Link href={href} onClick={onClick} className={linkClassname} data-qa={qaAttribute} {...otherLinkProps}>
        {children}
      </Link>
    </div>
  );
};

export default ActiveLink;
