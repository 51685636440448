import { ReactElement } from 'react';

interface Props {
  className?: string;
  fillFirst?: string;
  fillSecond?: string;
}

const Wordmark = ({ className, fillFirst = 'black', fillSecond = '#FB35B5' }: Props): ReactElement => {
  return (
    <>
      <svg
        className={className}
        width="127"
        height="21"
        viewBox="0 0 127 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.02861 4.8362C4.45154 4.39396 4.95557 4.04249 5.5272 3.79337C6.09882 3.54425 6.72259 3.41872 7.38111 3.41872C7.79632 3.41872 8.21345 3.48631 8.62478 3.62149C9.03612 3.75668 9.44553 3.95752 9.84335 4.22016C10.2412 4.48279 10.6428 4.81495 11.0368 5.20891L11.0928 5.26491L12.6764 3.6157L12.6377 3.56356C12.2921 3.07884 11.8382 2.63853 11.2898 2.25616C10.7433 1.87572 10.1272 1.57446 9.45518 1.3601C8.78314 1.14574 8.08599 1.0376 7.38111 1.0376C6.38657 1.0376 5.44609 1.22685 4.58865 1.59957C3.73315 1.97228 2.96841 2.50528 2.3176 3.18312C1.66873 3.86096 1.15311 4.6566 0.788122 5.54686C0.421201 6.43906 0.235809 7.4143 0.235809 8.45133C0.235809 9.54244 0.407683 10.5563 0.745637 11.4659C1.08359 12.3774 1.57217 13.1808 2.19401 13.8509C2.81584 14.5229 3.56127 15.0443 4.40519 15.4055C5.24911 15.7647 6.18766 15.9481 7.19765 15.9481C7.87356 15.9481 8.55526 15.8612 9.22731 15.6874C9.89549 15.5155 10.5115 15.2722 11.06 14.969C11.6084 14.6639 12.0487 14.3182 12.3693 13.9416L12.406 13.8991L11.2125 11.9525L11.143 12.0221C10.834 12.331 10.4845 12.6053 10.1079 12.8351C9.73134 13.063 9.32193 13.2406 8.89128 13.3623C8.4587 13.4839 8.01647 13.5457 7.5723 13.5457C6.84232 13.5457 6.17028 13.426 5.57161 13.1904C4.97295 12.9548 4.45347 12.6111 4.02282 12.1688C3.59217 11.7247 3.25422 11.182 3.02055 10.5563C2.78495 9.93061 2.66521 9.22187 2.66521 8.45133C2.66521 7.72329 2.78881 7.04352 3.0302 6.42941C3.27353 5.81723 3.61148 5.28036 4.03441 4.83813L4.02861 4.8362Z"
          fill={fillFirst}
        />
        <path d="M16.7955 0.336426H14.5168V15.7394H16.7955V0.336426Z" fill={fillFirst} />
        <path
          d="M27.78 6.04901C27.3281 5.57588 26.7893 5.1993 26.1791 4.92894C25.5688 4.65664 24.8987 4.51953 24.19 4.51953C23.3866 4.51953 22.6315 4.66437 21.946 4.94825C21.2585 5.23213 20.6617 5.64154 20.1674 6.16102C19.6749 6.6805 19.2848 7.30234 19.0087 8.00914C18.7306 8.71595 18.5915 9.50773 18.5915 10.3652C18.5915 11.4466 18.8406 12.4161 19.335 13.2484C19.8275 14.0807 20.5169 14.745 21.384 15.224C22.2511 15.7029 23.2572 15.9443 24.3792 15.9443C24.8929 15.9443 25.424 15.8709 25.955 15.7261C26.4861 15.5812 26.9979 15.3823 27.4749 15.1332C27.9519 14.8841 28.3651 14.5886 28.6992 14.2545L28.7456 14.2082L27.5869 12.5802L27.5212 12.6362C26.994 13.0958 26.4977 13.4164 26.0497 13.5902C25.6016 13.764 25.1073 13.8528 24.5839 13.8528C23.8443 13.8528 23.1838 13.7041 22.6219 13.4087C22.0618 13.1151 21.6215 12.6922 21.3145 12.1515C21.0943 11.7653 20.9533 11.325 20.8896 10.8364H29.1531L29.1762 9.85341C29.1897 9.0906 29.0739 8.378 28.8306 7.73299C28.5872 7.08798 28.2358 6.52215 27.7839 6.04901H27.78ZM21.2314 8.24861C21.4844 7.72333 21.861 7.31972 22.3534 7.04742C22.8478 6.7732 23.4658 6.63416 24.19 6.63416C24.6419 6.63416 25.0667 6.73458 25.4529 6.93542C25.8372 7.13433 26.1617 7.41048 26.4147 7.75616C26.6657 8.09991 26.8144 8.4958 26.853 8.92645V8.99211H20.9765C21.0383 8.71981 21.1252 8.47262 21.2314 8.24861Z"
          fill={fillFirst}
        />
        <path
          d="M39.2588 6.23414C39.0638 5.96377 38.834 5.71658 38.5733 5.4945C38.216 5.19324 37.7969 4.95764 37.3238 4.78963C36.8487 4.62161 36.3215 4.53857 35.7518 4.53857C34.794 4.53857 33.9172 4.78576 33.1448 5.27242C32.3742 5.75907 31.7543 6.43691 31.3044 7.29048C30.8544 8.14213 30.6246 9.12895 30.6246 10.222C30.6246 11.315 30.8486 12.2845 31.2928 13.1419C31.737 14.0013 32.3375 14.6888 33.081 15.1889C33.8265 15.691 34.6627 15.944 35.5665 15.944C36.0956 15.944 36.6073 15.8591 37.0863 15.693C37.5652 15.5269 38.0094 15.3009 38.4033 15.0248C38.7355 14.7911 39.0213 14.5285 39.2588 14.2388V15.7393H41.5589V4.76645H39.2588V6.23414ZM39.3419 10.222C39.3419 10.9211 39.2028 11.5487 38.9286 12.0933C38.6544 12.634 38.272 13.0647 37.7912 13.3698C37.3122 13.6768 36.7503 13.8313 36.1226 13.8313C35.495 13.8313 34.9581 13.6749 34.485 13.3698C34.0119 13.0627 33.6314 12.6321 33.3572 12.0914C33.083 11.5487 32.9439 10.9191 32.9439 10.2201C32.9439 9.52098 33.083 8.91652 33.3572 8.37966C33.6314 7.84473 34.0099 7.41794 34.485 7.11088C34.9581 6.80383 35.5085 6.64934 36.1226 6.64934C36.7367 6.64934 37.3122 6.80576 37.7912 7.11088C38.272 7.41794 38.6544 7.84473 38.9286 8.37773C39.2028 8.91459 39.3419 9.53256 39.3419 10.2181V10.222Z"
          fill={fillFirst}
        />
        <path
          d="M80.0528 12.6283C79.9794 12.8465 79.906 13.0628 79.8288 13.2811C79.7477 13.0532 79.6801 12.8832 79.6202 12.7674L76.2407 4.81291L76.2194 4.76463H73.5757L78.6295 16.1817L76.7717 20.4766H79.232L81.084 15.6892L85.5353 4.87084L85.5798 4.7627H82.8954L80.0528 12.6245V12.6283Z"
          fill={fillFirst}
        />
        <path
          d="M52.105 5.08909C51.4194 4.72989 50.6431 4.55029 49.7779 4.55029C49.1252 4.55029 48.5092 4.67775 47.9298 4.93073C47.3505 5.18371 46.8735 5.53712 46.5007 5.98901V4.76851H44.2239V15.7414H46.5007V9.58677C46.5007 8.60188 46.7499 7.84679 47.25 7.31958C47.7502 6.79431 48.4261 6.52974 49.2778 6.52974C50.1294 6.52974 50.8034 6.79237 51.2958 7.31958C51.7883 7.84679 52.0355 8.60188 52.0355 9.58677V15.7414H54.293V9.24688C54.293 8.24847 54.0999 7.3949 53.7136 6.69002C53.3274 5.98322 52.7905 5.45022 52.105 5.09102V5.08909Z"
          fill={fillFirst}
        />
        <path
          d="M71.9688 1.24609V15.7395H69.6881V5.78819L65.2928 15.7395H63.6552L59.2386 5.78819V15.7395H56.9579V1.24609H59.424L64.4836 12.6284L69.522 1.24609H71.9688Z"
          fill={fillFirst}
        />
        <path
          d="M112.674 6.23414C112.479 5.96377 112.249 5.71658 111.988 5.4945C111.631 5.19324 111.212 4.95764 110.739 4.78963C110.264 4.62161 109.737 4.53857 109.167 4.53857C108.209 4.53857 107.332 4.78576 106.56 5.27242C105.789 5.75907 105.169 6.43691 104.719 7.29048C104.27 8.14213 104.04 9.12895 104.04 10.222C104.04 11.315 104.264 12.2845 104.708 13.1419C105.152 14.0013 105.753 14.6888 106.496 15.1889C107.242 15.691 108.078 15.944 108.982 15.944C109.511 15.944 110.022 15.8591 110.501 15.693C110.98 15.5269 111.424 15.3009 111.818 15.0248C112.151 14.7911 112.436 14.5285 112.674 14.2388V15.7393H114.974V4.76645H112.674V6.23414ZM112.757 10.222C112.757 10.9211 112.618 11.5487 112.344 12.0933C112.069 12.634 111.687 13.0647 111.206 13.3698C110.727 13.6768 110.165 13.8313 109.538 13.8313C108.91 13.8313 108.373 13.6749 107.9 13.3698C107.427 13.0627 107.047 12.6321 106.772 12.0914C106.498 11.5487 106.359 10.9191 106.359 10.2201C106.359 9.52098 106.498 8.91652 106.772 8.37966C107.047 7.84473 107.425 7.41794 107.9 7.11088C108.373 6.80383 108.924 6.64934 109.538 6.64934C110.152 6.64934 110.727 6.80576 111.206 7.11088C111.687 7.41794 112.069 7.84473 112.344 8.37773C112.618 8.91459 112.757 9.53256 112.757 10.2181V10.222Z"
          fill={fillSecond}
        />
        <path
          d="M125.4 12.1803L125.34 12.2556C125.057 12.6051 124.75 12.9064 124.427 13.1497C124.105 13.3911 123.768 13.5746 123.429 13.6943C123.089 13.814 122.733 13.8758 122.372 13.8758C121.758 13.8758 121.188 13.7136 120.682 13.393C120.175 13.0725 119.759 12.6341 119.452 12.0914C119.145 11.5488 118.989 10.9289 118.989 10.2453C118.989 9.56162 119.138 8.93592 119.433 8.38554C119.727 7.83709 120.13 7.40065 120.632 7.08587C121.132 6.77302 121.712 6.61466 122.353 6.61466C122.699 6.61466 123.064 6.68998 123.436 6.83675C123.813 6.98545 124.174 7.19015 124.51 7.44506C124.846 7.69998 125.124 7.98579 125.339 8.29284L125.398 8.37782L126.638 6.85606L126.673 6.81357L126.644 6.76723C126.225 6.08552 125.609 5.53707 124.809 5.14119C124.012 4.7453 123.116 4.54639 122.148 4.54639C121.181 4.54639 120.217 4.79937 119.418 5.29954C118.62 5.79971 117.979 6.48914 117.515 7.34851C117.052 8.20787 116.816 9.18311 116.816 10.2491C116.816 11.3151 117.052 12.2904 117.515 13.1497C117.979 14.0091 118.62 14.6985 119.418 15.1968C120.217 15.6969 121.134 15.9518 122.148 15.9518C123.035 15.9518 123.902 15.7491 124.726 15.3455C125.551 14.9418 126.196 14.4224 126.642 13.7947L126.677 13.7465L125.404 12.1841L125.4 12.1803Z"
          fill={fillSecond}
        />
        <path
          d="M102.201 1.24609V15.7395H99.9205V5.78819L95.5252 15.7395H93.8876L89.471 5.78819V15.7395H87.1903V1.24609H89.6564L94.7161 12.6284L99.7545 1.24609H102.201Z"
          fill={fillSecond}
        />
      </svg>
    </>
  );
};

export { Wordmark };
