export interface AnalyticEvent {
  eventAction: string;
  eventCategory: string;
  eventLabel: string;
  eventNonInteraction?: boolean;
}

export const useAnalytics = () => {
  const sendEvent = ({ eventAction, eventCategory, eventLabel, eventNonInteraction = false }: AnalyticEvent) => {
    window.dataLayer.push({
      event: 'macpaw',
      eventAction,
      eventCategory,
      eventLabel,
      eventNonInteraction,
    });
  };

  return { sendEvent };
};
